import React from 'react'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Callout from '../components/callout'

const TemplateClient = ( {data} ) => {
	const {
		clientJson: {name, images},
	} = data
	return (
		<Layout>
			<SEO title={name}/>
			<main className="px-4 pt-3 lg:pt-12 mb-12 container">
				<h1 className="text-lg lg:text-2xl text-center mb-6">{name}</h1>
				<ul>
					{images &&
					images.map( ( image, index ) => (
						<li className="mb-3 lg:mb-12" key={index}>
							<Img className="shadow" fluid={image.childImageSharp.fluid}/>
						</li>
					) )}
				</ul>
			</main>
			<aside>
				<div className=" container">
					<li className="bg-tan flex justify-center items-center py-10 px-4 lg:px-0">
						<div className="max-w-sm relative">
							<h2 className="text-base lg:text-lg mb-2">Delivery</h2>
							<p className="text-xs mb-6">
								Your art and your time are valuable. We have a dedicated driver
								on our team who can help facilitate your framing needs. Whether
								you need help bringing in your large painting, or if visiting
								our showroom doesn’t fit into your schedule, we can pick up your
								project, design in person or through email, and then deliver
								back to you when finished.
							</p>
							<Img
								className="lg:bottom-0 lg:-right-40 absolute mx-auto lg:-mt-24 lg:mr-10"
								fluid={data.deliveryTruck.childImageSharp.fluid}
								style={{width: `191px`, height: `90px`}}
							/>
						</div>
					</li>
				</div>
				<Callout/>
			</aside>
		</Layout>
	)
}

export const query = graphql`
  query GET_CLIENT($slug: String!) {
    clientJson(slug: { eq: $slug }) {
      name
      description
      images {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    calloutImage: file(
      relativePath: { eq: "sample-remove-later/frames3.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    deliveryTruck: file(relativePath: { eq: "delivery-truck.png" }) {
      childImageSharp {
        fluid(maxWidth: 382, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default TemplateClient
